import * as React from 'react';
import { BaseLayout } from '../components';
import { Container } from 'reactstrap';
import termsData from '../data/terms.json';
import Markdown from '../components/Markdown/Markdown';

export const TermsPageInternal = (terms: typeof termsData) => {
  return (
    <BaseLayout title="Terms of Use">
      <Container className="header-padding">
        <Markdown source={terms.termsOfUse} />
      </Container>
    </BaseLayout>
  );
};

export default () => <TermsPageInternal {...termsData} />;
